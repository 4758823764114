.page-404 {
  height: 60vh;
  display: flex;
  align-items: center;

  p {
    color: var(--text-default);

    a {
      color: var(--text-default);
      text-decoration: underline;

      &:hover {
        color: var(--text-default-hover);
      }
    }
  }
}
